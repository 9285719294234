$primary-color: #73002E;
$secondary-color: #910039;
$default-color: #383838;
$blue: #4B75BE;
$white: #ffffff;

.global-nav-btns {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
    @media only screen and (min-width: 1241px) {
        padding: 0 65px;
    }
    li {
        flex: 1;
        .btn {
            &.btn-bordered-white {
                font-family: "Open Sans", sans-serif;
                color: #fff;
                border: solid 1px #fff;
                padding: 5px 18px;
                border-radius: 5px;
                text-decoration: none;
                display: block;
                text-align: center;
                &:hover {
                    color: $default-color;
                    background-color: $white;
                    &::before {
                        background-image: url(/images/user-icon-hover.svg) !important;
                    }
                }
                @media only screen and (min-width: 768px) {
                    display: initial;
                }
                &.btn-mytroy {
                    position: relative;
                    text-indent: 15px;
                    padding-left: 40px;
                    &::before {
                        position: absolute;
                        background-image: url(/images/user-icon.svg);
                        width: 18px;
                        height: 18px;
                        content: "";
                        background-repeat: no-repeat;
                        left: 50%;
                        top: 7px;
                        transform: translateX(-45px);
                    }
                }
            }
        }
    }
}

.start-apply-wrap {
    background-color: $secondary-color;
    padding: 22px 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    display: none;
    @media only screen and (max-width: 768px) {
        display: block;
    }
    .btn.btn-apply {
        background-color: $blue;
        color: $white;
        padding: 15px 30px;
        border-radius: 60px;
        font-size: 14px;
        display: block;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
    }
}